<template>
  <section class="user-edit-container">
    <v-tabs v-model="active">
      <template v-if="modules.default.SsoUsers">
        <v-tab :ripple="{ class: 'accent--text' }">Gamechanger SSO</v-tab>
        <v-tab-item px-0>
          <user-edit-details />
        </v-tab-item>
      </template>

      <template v-if="modules.default.Streamline">
        <v-tab :ripple="{ class: 'accent--text' }">Streamline</v-tab>
        <v-tab-item px-0 class="main-body">
          <user-edit-streamline />
        </v-tab-item>
      </template>
    </v-tabs>
  </section>
</template>

<script>
import UserEditDetails from "@/components/Users/Cognito/UserEdit/UserEditDetails.vue"
import UserEditStreamline from "@/components/Users/Cognito/UserEdit/UserEditStreamline.vue"

export default {
  name: "UserEditContainer",

  components: {
    UserEditDetails,
    UserEditStreamline
  },

  data:() => ({
    active: null,
    modules: null
  }),

  created() {
    this.modules = require("../../../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")
  },

}
</script>

<style scoped lang="scss">

</style>
