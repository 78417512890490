<template>
  <section v-if="userData" class="user-streamline">
    <v-alert :value="userData.attributes.bypassStreamlineSessionChecks" color="warning" icon="warning">
      User is bypassing checks
    </v-alert>

    <h3 class="subheading">User Info</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="sso-id">SSO ID</label>
          <v-text-field
            readonly
            id="sso-id"
            v-model="userData.id"
            solo
            flat
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>The SSO ID stored in the entitlements table</p>
        </v-flex>
      </v-layout>

      <v-layout v-if="modules.default.PreventEntitlementEmails" row>
        <v-flex mr-5 class="half">
          <label class="label" for="email">Email</label>
          <v-text-field
            readonly
            id="email"
            v-model="userData.attributes.userEmail"
            solo
            flat
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>Emails are prevented from being stored in the entitlements table in this configuration</i></p>
        </v-flex>
      </v-layout>

      <v-layout v-else row>
        <v-flex mr-5 class="half">
          <label class="label" for="email">Email</label>
          <v-text-field
            id="email"
            v-model="userData.attributes.userEmail"
            solo
            flat
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>The email address stored alongside the SSO ID in the entitlements table</p>
          <p><i>Warning: this record is not directly linked to the SSO and has the potential to be out of sync</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="email">Stripe Customer ID</label>
          <v-text-field
            :readonly="userData.attributes.stripeCustomerID ? true : false"
            id="email"
            v-model="userData.attributes.stripeCustomerID"
            solo
            flat
          ></v-text-field>
        </v-flex>
        <v-flex mt-4 class="half">
          <p><i>View in <a :href="`https://dashboard.stripe.com/customers/${userData.attributes.stripeCustomerID}`" target="_blank">Stripe dashboard</a></i></p>
        </v-flex>
      </v-layout>
    </section>

    <h3 class="subheading">User Permissions</h3>
    <section class="section-container">
      <v-layout row>
        <v-flex mr-5 class="half">
          <label class="label" for="bypass">Bypass</label>
          <v-select
            id="bypass"
            v-model="userData.attributes.bypassStreamlineSessionChecks"
            item-text='name'
            item-value='value'
            :items="[
              { name: 'Use standard checks', value: 0 },
              { name: 'Bypass checks', value: 1 }
            ]"
            solo
            flat
            @change="status = 'needsSaving'"/>
        </v-flex>
        <v-flex mt-4 class="half">
          <p>Bypass a users restrictions. This will allow them access vod and live streaming regardless of entitlements, region, concurrency and all other checks. The only requirement is that they are logged in</p>
          <p>This will not affect other georestrictions such as Packages</p>
          <p><i>Warning: this removes all checks and should only be used as a last resort</i></p>
        </v-flex>
      </v-layout>

      <v-layout row>
        <v-flex mr-5 class="half">
          <entitlements-picker
            :activeEntitlements="userData.attributes.entitlements"
            @updated="updatedEntitlements($event)" />
        </v-flex>
        <v-flex mt-4 class="half">
          <p>View and edit a users entitlements directly</p>
        </v-flex>
      </v-layout>
    </section>

    <h2 class="subheading">Stored record (JSON)</h2>
    <section class="section-container">
      <v-layout row>
        <v-flex>
          <pre class="rawOutput" v-html="userData"></pre>
        </v-flex>
      </v-layout>
    </section>

    <StickyFooter :errorMessage="errorMessage" :status="status" :buttonText="'Save User'" @buttonClicked="saveUser()" />
  </section>

  <section v-else-if="noUser">
    No user found

    <v-btn
      color="accent"
      type="button"
      depressed
      @click="createUser()"
    >
      Create User
    </v-btn>
  </section>
</template>

<script>
import axios from "axios"
import EntitlementsPicker from "@/components/Library/EntitlementsPicker"
import StickyFooter from "@/components/Library/StickyFooter"

export default {
  name: "UserEditStreamline",

  components: {
    EntitlementsPicker,
    StickyFooter
  },

  data:() => ({
    api: `${process.env.VUE_APP_STREAMLINEAPI}/users`,
    modules: null,
    userData: null,
    status: "saved",
    errorMessage: "",
    noUser: false
  }),

  mounted() {
    this.modules = require("../../../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")

    this.fetchUser()
  },

  methods: {
    updateData() {
      this.status = "needsSaving";
      this.errorMessage = ""
      console.log(this.item);
    },

    fetchUser() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/${this.$route.params.id}`,
          headers: { "Authorization": jwt },
          validateStatus: function (status) {
            return status === 200 || status === 404
          },
        })
        .then(response => {
          console.log("Fetch streamline user:", response);
          if (response.status === 200) {
            this.userData = response.data.data
          } else if (response.status === 404) {
            this.noUser = true
          }

          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem getting streamline user", e);
        });
      })
    },

    saveUser() {
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PATCH",
          url: `${this.api}/${this.$route.params.id}`,
          headers: { "Authorization": jwt },
          data: {
            data: {
              type: this.userData.type,
              id: this.userData.id,
              attributes: {
                bypassStreamlineSessionChecks: this.userData.attributes.bypassStreamlineSessionChecks,
                entitlements: this.userData.attributes.entitlements,
                userEmail: this.userData.attributes.userEmail,
                stripeCustomerID: this.userData.attributes.stripeCustomerID
              }
            }
          }
        })
        .then(response => {
          console.log("Saved streamline user:", response);
          
          this.fetchUser()

          this.$store.commit("completeLoading");
          this.status = "saved";
        })
        .catch(e => {
          console.error("Problem saving streamline user", e);

          this.$store.commit("completeLoading");
          this.status = "needsSaving";
          this.errorMessage = "Problem saving streamline user";
        });
      })
    },

    createUser() {
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "PUT",
          url: `${this.api}`,
          headers: { "Authorization": jwt },
          data: {
            data: {
              type: "streamline-user",
              id: this.$route.params.id,
              attributes: {}
            }
          }
        })
        .then(response => {
          console.log("Created streamline user:", response);
          
          this.fetchUser()

          this.$store.commit("completeLoading");
          this.status = "saved";
        })
        .catch(e => {
          console.error("Problem saving streamline user", e);

          this.$store.commit("completeLoading");
          this.status = "needsSaving";
          this.errorMessage = "Problem saving streamline user";
        });
      })
    },

    updatedEntitlements(payload) {
      const filteredEntitlements = payload.filter(element => element !== undefined);

      this.userData.attributes.entitlements = filteredEntitlements

      this.updateData()
    }
  }
}
</script>

<style scoped lang="scss">
  .half {
    width: 50%;
  }

  .rawOutput {
    font-size: 9pt;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;
  }
</style>
