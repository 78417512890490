<template>
    <section class="player">
        <v-form
            v-model="valid"
            ref="playerForm"
            lazy-validation
        >
            <v-layout class="main-body">
                <v-flex>
                    <h2 class="subheading">User status</h2>
                    <div class="player__panel">
                        <v-flex
                            align-center
                            class="player__panel-details"
                        >
                            <v-layout>
                                <div class="flex sm12 md5">
                                    <p><strong>Created:</strong> {{ updatedData.created | moment('DD/MM/YYYY (HH:mm:ss)') }}</p>
                                    <p><strong>Modified:</strong> {{ updatedData.modified | moment('DD/MM/YYYY (HH:mm:ss)') }}</p>
                                    <p><strong>Status:</strong> {{ updatedData.status }}</p>

                                    <div v-if="updatedData.status === 'UNCONFIRMED'">
                                        User has been created but not confirmed.
                                        <v-btn
                                            class="v-btn__content"
                                            color="#c0c0c0"
                                            dark
                                            type="button"
                                            depressed
                                            @click="confirmSignup()"
                                        >
                                            Confirm User
                                        </v-btn>
                                    </div>
                                    <div v-if="updatedData.status === 'CONFIRMED'">User has been confirmed.</div>
                                    <div v-if="updatedData.status === 'ARCHIVED'">User is no longer active..</div>
                                    <div v-if="updatedData.status === 'COMPROMISED'">User is disabled due to a potential security threat.
                                    </div>
                                    <div v-if="updatedData.status === 'UNKNOWN'">User status is not known</div>
                                    <div v-if="updatedData.status === 'RESET_REQUIRED'">User is confirmed, but the user must request a code
                                        and reset his or her password before he or she can sign in.</div>
                                    <div v-if="updatedData.status === 'FORCE_CHANGE_PASSWORD'">The user is confirmed and the user can sign
                                        in using a temporary password, but on first sign-in, the user must change his or her password to a
                                        new value before doing anything else.</div>
                                    <!-- <div v-if="updatedData.emailVerified === 'false'">This user is unable to receive emails as they have not completed the automated verification process.</div> -->
                                </div>

                                <div class="flex sm12 md5">
                                    <v-flex align-left>
                                        <v-layout>
                                            <template v-if="updatedData.enabled === true">
                                                <div class="flex sm12 md5">
                                                    User currently enabled
                                                </div>
                                                <div class="flex sm12 md5">
                                                    <v-btn
                                                        class="save-status"
                                                        color="#FFA500"
                                                        dark
                                                        type="button"
                                                        depressed
                                                        @click="disableUser()"
                                                    >
                                                        Disable User
                                                    </v-btn>
                                                </div>
                                            </template>

                                            <template v-else-if="updatedData.enabled === false">
                                                <div class="flex sm12 md5">
                                                    User currently disabled
                                                </div>
                                                <div class="flex sm12 md5">
                                                    <v-btn
                                                        class="save-status"
                                                        color="#5EA77E"
                                                        dark
                                                        type="button"
                                                        depressed
                                                        @click="enableUser()"
                                                    >
                                                        Enable User
                                                    </v-btn>
                                                    <confirmation-modal
                                                        @confirmed="deleteUser()"
                                                        title="Delete User"
                                                        text="This action is permanent, are you sure you want to delete this user?"
                                                    >
                                                        <v-btn
                                                            class="save-status"
                                                            color="#FF0000"
                                                            dark
                                                            type="button"
                                                            depressed
                                                        >
                                                            Delete User
                                                        </v-btn>
                                                    </confirmation-modal>
                                                </div>
                                            </template>

                                        </v-layout>
                                        <v-layout>
                                            <template>
                                                <div class="flex sm12 md5">
                                                    Set a temporary password for the user
                                                </div>
                                                <div class="flex sm12 md5">
                                                    <confirmation-modal
                                                        @confirmed="setPassword($event)"
                                                        title="Set Temporary Password"
                                                        text="Setting a temporary password on behalf of the user is not advised except in specific cases. This should only be done at the client's own risk."
                                                        :hasInput="true"
                                                        inputType="password"
                                                        cancelButton="cancel"
                                                        confirmButton="Set Password"
                                                    >
                                                        <v-btn
                                                            class="save-status"
                                                            color="accent"
                                                            dark
                                                            type="button"
                                                            depressed
                                                        >
                                                            Set Password
                                                        </v-btn>
                                                    </confirmation-modal>
                                                </div>
                                            </template>
                                        </v-layout>
                                        <v-layout>
                                            <template>
                                                <div class="flex sm12 md5">
                                                    Reset the user's password
                                                </div>
                                                <div class="flex sm12 md5">
                                                    <v-btn
                                                        class="save-status"
                                                        color="accent"
                                                        dark
                                                        type="button"
                                                        depressed
                                                        @click="resetPassword()"
                                                    >
                                                        Reset Password
                                                    </v-btn>
                                                </div>
                                            </template>
                                        </v-layout>

                                    </v-flex>
                                </div>
                            </v-layout>
                        </v-flex>
                    </div>

                    <h2 class="subheading">User details</h2>
                    <div class="player__panel">
                        <v-flex
                            align-center
                            class="player__panel-details"
                        >
                            <v-layout>
                                <div class="flex sm12 md5 ">
                                    <label
                                        class="label"
                                        for="firstName"
                                    >SSO UID</label>
                                    <v-text-field
                                        id="username"
                                        :rules="[rules.required]"
                                        readonly
                                        disabled
                                        validate-on-blur
                                        placeholder=""
                                        solo
                                        flat
                                        v-model="updatedData.username"
                                    ></v-text-field>
                                </div>
                            </v-layout>
                            <v-layout>
                                <div class="flex sm12 md5">
                                    <label
                                        class="label"
                                        for="firstName"
                                    >Email</label>
                                    <v-text-field
                                        id="email"
                                        :rules="emailRules"
                                        validate-on-blur
                                        placeholder=""
                                        solo
                                        flat
                                        v-model="updatedData.email"
                                        persistent-hint
                                        @change="updateData()"
                                    ></v-text-field>
                                </div>
                                <div class="flex sm12 md5">
                                    <label
                                        class="label"
                                        for="firstName"
                                    >Email Verification</label>
                                    <v-select
                                        id="emailVerified"
                                        v-model="updatedData.emailVerified"
                                        value="item.value"
                                        text="item.text"
                                        :items="[{ text: 'User email is verified', value: 'true' }, { text: 'User email is not verified', value: 'false' }]"
                                        flat
                                        solo
                                        @change="updateData()"
                                    >
                                    </v-select>
                                </div>
                            </v-layout>
                            <v-layout class="mt5">

                            </v-layout>
                            <v-layout>
                                <div class="flex sm12 md5">
                                    <label
                                        class="label"
                                        for="firstName"
                                    >First name</label>
                                    <v-text-field
                                        id="firstName"
                                        :rules="[rules.required]"
                                        validate-on-blur
                                        placeholder="First name"
                                        solo
                                        flat
                                        v-model="updatedData.firstName"
                                        @change="updateData()"
                                    ></v-text-field>
                                </div>
                                <div class="flex sm12 md5">
                                    <label
                                        class="label"
                                        for="middleName"
                                    >Middle name</label>
                                    <v-text-field
                                        id="middleName"
                                        placeholder="Middle name"
                                        solo
                                        flat
                                        v-model="updatedData.middleName"
                                        @change="updateData()"
                                    ></v-text-field>
                                </div>

                            </v-layout>
                            <v-layout>
                                <div class="flex sm12 md5">
                                    <label
                                        class="label"
                                        for="lastName"
                                    >Last name</label>
                                    <v-text-field
                                        id="lastName"
                                        :rules="[rules.required]"
                                        validate-on-blur
                                        placeholder="Last name"
                                        solo
                                        flat
                                        v-model="updatedData.lastName"
                                        @change="updateData()"
                                    ></v-text-field>
                                </div>
                                <div class="flex sm12 md5">
                                    <label
                                        class="label"
                                        for="nickname"
                                    >Nickname</label>
                                    <v-text-field
                                        id="nickname"
                                        placeholder="Nickname"
                                        solo
                                        flat
                                        v-model="updatedData.nickname"
                                        @change="updateData()"
                                    ></v-text-field>
                                </div>
                            </v-layout>
                            <v-layout>
                                <div class="flex sm12 md5">
                                    <label
                                        class="label"
                                        for="gender"
                                    >Gender</label>
                                    <v-select
                                        id="gender"
                                        class="calendar-listing__filter"
                                        :items="[
                                            {
                                                name: 'Female',
                                                value: 'female'
                                            },
                                            {
                                                name: 'Male',
                                                value: 'male'
                                            },
                                            {
                                                name: 'Non-binary',
                                                value: 'non-binary'
                                            },
                                            {
                                                name: 'Other',
                                                value: 'other'
                                            },
                                            {
                                                name: 'Prefer not to say',
                                                value: ''
                                            },
                                        ]"
                                        item-value="value"
                                        item-text="name"
                                        v-model="updatedData.gender"
                                        solo
                                        flat
                                        label="Gender"
                                    ></v-select>
                                </div>

                                <div class="flex sm12 md5">
                                    <label
                                        class="label"
                                        for="phone"
                                    >Phone Number (numbers only)</label>

                                    <v-text-field
                                        id="phone"
                                        placeholder="Phone"
                                        solo
                                        flat
                                        v-model="updatedData.phone"
                                        prepend-inner-icon="add"
                                    ></v-text-field>
                                </div>
                            </v-layout>

                        </v-flex>
                        <v-flex
                            align-center
                            class="player__panel-details"
                        >
                            <v-layout>
                                <div class="flex sm3 md5 ">
                                    <label
                                        class="label"
                                        for="updatedData.dobDay"
                                    >Date of Birth</label>
                                    <v-select
                                        :items="days"
                                        label="DD"
                                        v-model="updatedData.dobDay"
                                        outline
                                    ></v-select>
                                </div>
                                <div class="flex sm3 md5 offset-md1">
                                    <label
                                        class="label"
                                        for="dobMonth"
                                    >&nbsp;</label>
                                    <v-select
                                        :items="months"
                                        label="MM"
                                        v-model="updatedData.dobMonth"
                                        outline
                                    ></v-select>
                                </div>
                                <div class="flex sm3 md5 offset-md1">
                                    <label
                                        class="label"
                                        for="updatedData.dobYear"
                                    >&nbsp;</label>
                                    <v-select
                                        :items="years"
                                        label="YYYY"
                                        v-model="updatedData.dobYear"
                                        outline
                                    ></v-select>
                                </div>
                            </v-layout>
                        </v-flex>
                    </div>

                    <h2 class="subheading">Address</h2>
                    <div class="player__panel">
                        <v-flex
                            align-center
                            class="player__panel-details"
                        >

                            <address
                                v-if="updatedData.address"
                                class="player__panel-details-address"
                            >

                                <v-text-field
                                    id="street_address"
                                    placeholder="Street Address"
                                    flat
                                    v-model="updatedData.address.street_address"
                                    label="Street Address"
                                ></v-text-field>
                                <v-text-field
                                    id="locality"
                                    placeholder="Locality"
                                    flat
                                    v-model="updatedData.address.locality"
                                    label="Locality"
                                ></v-text-field>
                                <v-text-field
                                    id="region"
                                    placeholder="Region"
                                    flat
                                    v-model="updatedData.address.region"
                                    label="Region"
                                ></v-text-field>
                                <v-text-field
                                    id="postal code"
                                    placeholder="Postal code"
                                    flat
                                    v-model="updatedData.address.postal_code"
                                    label="Postal code"
                                ></v-text-field>
                                <v-text-field
                                    id="country"
                                    placeholder="Country"
                                    flat
                                    v-model="updatedData.address.country"
                                    label="Country"
                                ></v-text-field>
                            </address>
                        </v-flex>
                    </div>

                    <h2 class="subheading">Preferences and Custom Attributes</h2>
                    <div class="player__panel user-attributes">
                        <div
                            v-for="(pref, index) in preferences"
                            :key="index"
                            class="user-attributes__attribute"
                        >
                            <span class="pref__title">{{ pref.label }}: </span>
                            <span
                                class="pref__value"
                                :class="{ 'enabled': pref.value !== 'false' }"
                            >{{ pref.value }}</span>
                        </div>

                    </div>

                    <!-- <h2 class="subheading">User picture</h2>
        <div class="player__panel">
          <v-flex align-center class="player__panel-details">
            <img v-if="updatedData.picture" :src="updatedData.picture" />
            <p v-else class="missing-data">No image uploaded</p>
          </v-flex>
        </div> -->

                    <!-- <h2 class="subheading">Stored record (JSON)</h2>
                    <div class="player__panel">
                        <v-flex
                            align-center
                            class="player__panel-details"
                        >
                            <pre
                                class="rawOutput"
                                v-html="userDetails"
                            ></pre>
                        </v-flex>
                    </div> -->

                </v-flex>
            </v-layout>
        </v-form>

        <StickyFooter
            :errorMessage="errorMessage"
            :status="status"
            :buttonText="'Save User'"
            @buttonClicked="create()"
        />
    </section>
</template>

<script>
import axios from "axios"
import StickyFooter from "@/components/Library/StickyFooter"
import ConfirmationModal from "@/components/Library/GlobalModals/ConfirmationModal";


export default {
    name: "UserManagement",

    components: {
        ConfirmationModal,
        StickyFooter,
    },

    data: () => {
        return {
            api: `${process.env.VUE_APP_SSO_COGNITO_API}`,
            updatedData: {},
            status: "saved",
            errorMessage: "",
            dateModalDob: null,
            dateModalDoj: null,
            valid: false,
            saveDisabled: true,
            days: [""],
            months: [""],
            years: [""],
            rules: {
                required: value => !!value || "Required."
                // groups: value => !!this.updatedData.isContent || 'Required.'
            },
            emailRules: [
                value => !!value || "Email address is required",
                value => /.+@.+/.test(value) || "Email must be valid"
            ],
            userDetails: null,
            preferences: null,
        }
    },

    mounted() {
        this.populateDates()
        this.updatedData = {
            username: null,
            email: null,
            emailVerified: null,
            firstName: null,
            middleName: null,
            lastName: null,
            nickname: null,
            created: null,
            modified: null,
            status: null,
            enabled: null,
            birthdate: null,
            dobDay: null,
            dobMonth: null,
            dobYear: null,
            picture: null,
            gender: null,
            phone: null,
            address: {},
            clubMarketing: [],
            partnerMarketing: [],
            foundationMarketing: []
        }

        this.getUser()

        this.dataLoaded = true
        this.status = "needsSaving"
        this.$store.commit("completeLoading")
    },

    methods: {
        pad(num, size) {
            var s = num + "";
            while (s.length < size) s = "0" + s;
            return s;
        },
        updateData() {
            this.$emit("update", this.updatedData)
            console.log(this.updatedData);
        },
        populateDates() {
            console.log("ADDING DATES");
            for (var d = 1; d < 32; d++) {
                this.days.push(d);
            }
            for (var m = 1; m < 13; m++) {
                this.months.push(m);
            }
            var thisYear = new Date().getFullYear();
            for (var y = thisYear + 1; y > 1900; y--) {
                this.years.push(y);
            }
        },
        create() {
            this.errorMessage = ""
            if (this.$refs.playerForm.validate()) {
                console.log("Validation passed");
                this.saveUser();
            } else {
                this.errorMessage = "Certain entries are invalid or required"
            }
        },
        disableUser() {
            console.log("disable user")
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "PATCH",
                    url: `${this.api}/${this.$route.params.id}`,
                    headers: { "Authorization": jwt },
                    data: {
                        data: {
                            id: this.updatedData.username,
                            type: "sso-user",
                            attributes: {
                                disable: "true",
                            }
                        }
                    }
                })
                    .then(response => {
                        console.log("user disabled", response)
                        this.getUser()
                    })
                    .catch(e => {
                        console.error("Problem disabling user", e);
                        this.errorMessage = "Problem disabling user";
                    });
            })
        },
        enableUser() {
            console.log("enable user")
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "PATCH",
                    url: `${this.api}/${this.$route.params.id}`,
                    headers: { "Authorization": jwt },
                    data: {
                        data: {
                            id: this.updatedData.username,
                            type: "sso-user",
                            attributes: {
                                enable: "true"
                            }
                        }
                    }
                })
                    .then(response => {
                        console.log("user enabled", response)
                        this.getUser()
                    })
                    .catch(e => {
                        console.error("Problem enabling user", e);
                        this.errorMessage = "Problem enabling user";
                    });
            })
        },
        deleteUser() {
            console.log("delete user")
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "DELETE",
                    url: `${this.api}/${this.$route.params.id}`,
                    headers: { "Authorization": jwt },
                })
                    .then(response => {
                        console.log("user deleted", response)
                        this.$router.push("/users")
                    })
                    .catch(e => {
                        console.error("Problem deleting user", e);
                        this.errorMessage = "Problem deleting user";
                    });
            })
        },
        resetPassword() {
            console.log("reset password for user")
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "PATCH",
                    url: `${this.api}/${this.$route.params.id}`,
                    headers: { "Authorization": jwt },
                    data: {
                        data: {
                            id: this.updatedData.username,
                            type: "sso-user",
                            attributes: {
                                "reset_password": "true"
                            }
                        }
                    }
                })
                    .then(response => {
                        console.log("user password reset", response)
                        this.getUser()
                    })
                    .catch(e => {
                        console.error("Problem resetting password for user", e);
                        console.error("Problem resetting password for user", e.response);

                        if (e.response.data.errors && e.response.data.errors.length) {
                            this.errorMessage = e.response.data.errors[0].detail
                        } else {
                            this.errorMessage = "Problem resetting user password";
                        }
                    });
            })
        },
        setPassword(password) {
            console.log("set pass", password)
            this.errorMessage = ""
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "PATCH",
                    url: `${this.api}/${this.$route.params.id}`,
                    headers: { "Authorization": jwt },
                    data: {
                        data: {
                            id: this.updatedData.username,
                            type: "sso-user",
                            attributes: {
                                set_password: "true",
                                password,
                            }
                        }
                    }
                })
                    .then(response => {
                        this.getUser()
                    })
                    .catch(e => {
                        console.error("Problem setting password user", e);
                        if (
                            e.response &&
                            e.response.data.errors &&
                            e.response.data.errors[0] &&
                            e.response.data.errors[0].detail
                        ) {
                            this.errorMessage = e.response.data.errors[0].detail;
                        } else {
                            this.errorMessage = "Problem setting password";
                        }
                    });
            })
        },
        confirmSignup() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "PATCH",
                    url: `${this.api}/${this.$route.params.id}`,
                    headers: { "Authorization": jwt },
                    data: {
                        data: {
                            id: this.updatedData.username,
                            type: "sso-user",
                            attributes: {
                                confirm_signup: "true"
                            }
                        }
                    }
                })
                    .then(response => {
                        this.getUser()
                    })
                    .catch(e => {
                        console.error("Problem confirming user", e);
                        this.errorMessage = "Problem confirming user";
                    });
            })
        },
        saveUser() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {

                var data = {
                    given_name: this.updatedData.firstName || null,
                    middle_name: this.updatedData.middleName || null,
                    family_name: this.updatedData.lastName || null,
                    nickname: this.updatedData.nickname || null,
                    gender: this.updatedData.gender || null,
                    email: this.updatedData.email || null,
                    email_verified: this.updatedData.emailVerified || null,
                    phone_number: this.updatedData.phone ? `+${this.updatedData.phone.trim()}` : null,
                    address: this.updatedData.address ? JSON.stringify(this.updatedData.address) : null
                }

                // Only pass birthdate if it has been successfully set
                if (this.updatedData.dobDay && this.updatedData.dobMonth && this.updatedData.dobYear) {
                    console.log("Setting DoB")
                    data["birthdate"] =
                        this.updatedData.dobYear +
                        "-" +
                        this.pad(this.updatedData.dobMonth, 2) +
                        "-" +
                        this.pad(this.updatedData.dobDay, 2);
                    console.log("data", data)
                } else if (
                    !this.updatedData.dobDay && !this.updatedData.dobMonth && !this.updatedData.dobYear
                ) {
                    data["birthdate"] = null
                }


                axios({
                    method: "PATCH",
                    url: `${this.api
                        }/${this.$route.params.id} `,
                    headers: { "Authorization": jwt },
                    data: {
                        data: {
                            id: this.updatedData.username,
                            type: "sso-user",
                            attributes: data

                        }
                    }
                })
                    .then(response => {
                        console.log("Saved player:", response);
                        console.log("USER Updated", response.data)
                        this.status = "saved";
                        if (response.data.success === true) {
                            console.log("Update user success")
                        }
                    })
                    .catch(e => {
                        console.error("Problem editing admin user", e);
                        this.status = "needsSaving";

                        if (e.response.data.errors && e.response.data.errors.length) {
                            this.errorMessage = e.response.data.errors[0].detail
                        } else {
                            this.errorMessage = "Problem editing user";
                        }
                    });
            })
        },
        getUserAttributes(userAttributes, clientRefNo) {

            this.$getCurrentUserJwToken().subscribe((jwt) => {

                axios({
                    method: "GET",
                    url: `${this.api}/attributes`,
                    headers: { "Authorization": jwt },
                    data: {
                        data: {
                            id: this.updatedData.username,
                            type: "sso-user",
                            attributes: {}
                        }
                    }
                })
                    .then(response => {
                        console.log("Attributes:", response);
                        this.preferences = {}

                        response.data.data.attributes.forEach(pref => {
                            if (pref.includes("custom:")) {
                                if (pref.includes("client_ref_no")) {
                                    this.preferences[pref] = {
                                        label: "Fan ID",
                                        value: clientRefNo,
                                    }
                                } else {
                                    this.preferences[pref] = {
                                        label: pref.replaceAll("_", " ").replace("custom:", ""),
                                        value: userAttributes.includes(pref) ? "true" : "false",
                                    }
                                }
                            }
                        })
                    })
                    .catch(e => {
                        console.error("Problem fetching user attributes", e);
                        this.errorMessage = "Problem fetching user attributes";
                    });
            })
        },
        isValidJSON(str) {
            try {
                JSON.parse(str);
                return true;
            } catch (e) {
                return false;
            }
        },
        getUser() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {

                axios({
                    method: "GET",
                    url: `${this.api}/${this.$route.params.id}`,
                    headers: { "Authorization": jwt }
                })
                    .then(response => {
                        var user = response.data.data

                        // Set the raw viewable record
                        this.userDetails = JSON.stringify(user, null, 2)

                        // initialise marketing pref arrays
                        user["clubMarketing"] = []
                        user["partnerMarketing"] = []
                        user["foundationMarketing"] = []

                        // extract attributes to become user properties
                        // user.UserAttributes.forEach(function (element) {
                        //     // if it is a marketing preference place in relevant array
                        //     if (element.Name.includes("custom:club_")) {
                        //         user["clubMarketing"].push({ [element.Name.replace("custom:club_", "")]: element.Value })
                        //     } else if (element.Name.includes("custom:partner_")) {
                        //         user["partnerMarketing"].push({ [element.Name.replace("custom:partner_", "")]: element.Value })
                        //     } else if (element.Name.includes("custom:foundation_")) {
                        //         user["foundationMarketing"].push({ [element.Name.replace("custom:foundation_", "")]: element.Value })
                        //     } else {
                        //         // otherwise keep at the root of the user
                        //         user[element.Name] = element.Value
                        //     }
                        // });
                        this.updatedData = {
                            username: user.attributes.sub,
                            firstName: user.attributes.given_name,
                            middleName: user.attributes.middle_name,
                            lastName: user.attributes.family_name,
                            nickname: user.attributes.nickname,
                            email: user.attributes.email,
                            emailVerified: user.attributes.email_verified,
                            created: user.attributes.createdAt,
                            modified: user.attributes.lastModifiedAt,
                            status: user.attributes.status,
                            enabled: user.attributes.enabled,
                            birthdate: user.attributes.birthdate,
                            picture: user.picture,
                            gender: user.attributes.gender,
                            phone: user.attributes.phone_number ? user.attributes.phone_number.substring(1) : ""
                        }
                        if (user.attributes && user.attributes.address) {
                            const addressString = user.attributes.address

                            if (this.isValidJSON(addressString)) {
                                this.updatedData.address = JSON.parse(addressString)
                            } else {
                                this.updatedData.address = {}
                            }
                        }

                        if (this.updatedData.birthdate) {
                            var birthdayDates = this.updatedData.birthdate.split("-");
                            this.updatedData.dobDay = parseInt(birthdayDates[2]);
                            this.updatedData.dobMonth = parseInt(birthdayDates[1]);
                            this.updatedData.dobYear = parseInt(birthdayDates[0]);
                        }

                        const userAttributes = []
                        let clientRefNo
                        for (const [key, value] of Object.entries(user.attributes)) {
                            if (key.includes("custom:")) {
                                userAttributes.push(key)
                            }
                            if (key.includes("client_ref_no")) {
                                clientRefNo = value
                            }
                        }

                        this.getUserAttributes(userAttributes, clientRefNo)
                    })
                    .catch(e => {
                        console.error("Problem getting user", e);
                        this.status = "needsSaving";
                        this.errorMessage = "Problem getting user";
                    });
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.player {
    &__panel-details-address {
        >span {
            display: block;
        }
    }

    &__panel {
        border: 1px solid $lightgrey;
        background-color: $white;
        padding: 20px;
        margin-bottom: 20px;

        &-details {
            flex-direction: column;

            @media (min-width: 960px) {
                flex-direction: row;
            }
        }

        &-img {
            img {
                width: 100%;
            }
        }

        &-opta {
            &-cb {
                label {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.input {
    &__textfield {
        &--basic {
            margin-top: 0;
        }
    }
}

.pref {
    margin: 0 0 4px;

    &__title {
        text-transform: capitalize;
    }

    &__value {
        color: orangered;

        &.enabled {
            color: green;
        }
    }
}

.rawOutput {
    font-size: 9pt;
    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
}

.missing-data {
    margin: 0;
    padding: 0;
    font-family: monospace;
    font-size: 12px;
}

.layout {
    gap: 16px;
}

.user-attributes {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    &__attribute {
        flex: 1 1 calc(50% - 8px);
    }
}
</style>
