<template>
  <div>
    <label class="label" for="entitlement-picker">Entitlements</label>

    <v-autocomplete
      :readonly="readonly"
      id="entitlement-picker"
      item-text="id"
      return-object
      v-model="selectedEntitlements"
      :items="entitlements"
      no-data-text="loading..."
      multiple
      solo
      flat
      dense
      chips
      @change="updated()"
      >
    </v-autocomplete>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "EntitlementsPicker",

  props: {
    activeEntitlements: {
      type: Array,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data:() => ({
    entitlements: [],
    selectedEntitlements: []
  }),

  mounted() {
    this.fetchEntitlements()

    // console.warn('loaded entitlements', this.activeEntitlements)
  },

  methods: {
    fetchEntitlements() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_STREAMLINEAPI}/entitlements?page[size]=1000`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Fetch entitlements:", response);
          this.entitlements = response.data.data

          this.selectedEntitlements = JSON.parse(JSON.stringify(this.activeEntitlements))
        })
        .catch(e => {
          console.error("Problem fetching entitlements", e);
        });
      })
    },

    updated() {
      // console.warn('updated this.activeEntitlements', this.activeEntitlements)
      // console.warn('updated this.selectedEntitlements', this.selectedEntitlements)

      const selectedEntitlements = this.selectedEntitlements.map(item => {
        // console.warn('item', item)
        if (item.id) {
          return item.id
        } else {
          return item
        }
      }).filter(item => {
        return item !== ""
      })
      this.$emit("updated", selectedEntitlements)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
